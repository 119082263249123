<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isExistInvoiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked_no_refresh',false);$refs.simpleRules.reset();userData=blankUserData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Invoice
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >


        <validation-provider
            #default="validationContext"
            name="Birth"
            rules="required"
          >

            <b-form-group
              label="Date"
              label-for="Date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="selected.invoice_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="invoice_code"
            rules=""
          >
            <b-form-group
              label="Code"
              label-for="invoice_code"
            >
            <cleave
              id="invoice_code"
              v-model="selected.invoice_code"
              class="form-control"
              :raw="false"
              :options="options.block"

              placeholder="INVOICE ID"
            />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
              #default="validationContext"
              name="Client Name"
              rules="required"
            >
              <b-form-group
                label="Client"
                label-for="Client"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="selected.member_id"
                  @search="fetchOptions"
                  :options="memberOptions"
                  :clearable="true"
                  :filterable="false"
                  input-id="Client"
                >
                <template v-slot:spinner="{ loading }">
        <div v-show="loading">Loading...</div>
      </template>
      </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Amount"
            rules=""
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <!--<b-form-input
                id="amount"
                v-model="selected.amount"
                :state="getValidationState(validationContext)"
                trim
              />-->
              <cleave
                id="number"
                v-model="selected.amount"
                class="form-control"
                :raw="true"
                :options="options.number"

                placeholder="10000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="Amount"
            rules=""
          >
            <b-form-group
              label="Paid"
              label-for="Paid"
            >
              <!--<b-form-input
                id="amount"
                v-model="selected.amount"
                :state="getValidationState(validationContext)"
                trim
              />-->
              <cleave
                id="number"
                v-model="selected.paid"
                class="form-control"
                :raw="true"
                :options="options.number"

                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
              #default="validationContext"
              name="Sent at"

            >

              <b-form-group
                label="Sent at"
                label-for="Date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="selected.sent_at"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
                #default="validationContext"
                name="Settle at / Bank in Date"

              >

                <b-form-group
                  label="Settle at / Bank in Date"
                  label-for="Date"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    v-model="selected.settle_at"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Type"
          >
            <b-form-group
              label="Staff"
              label-for="staff_name"
            >
              <b-form-input
                id="staff_name"
                v-model="selected.staff_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
              #default="validationContext"
              name="Status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-for="status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="selected.status"
                  :options="statusOptions"
                  :clearable="true"
                  input-id="status"
                >
      </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          <validation-provider
          #default="validationContext"
          name="Price"
        >
          <b-form-group
            label="Note"
            label-for="Note"
          >
            <b-form-textarea
              id="Note"
              v-model="selected.remarks"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group
          v-if="!selected.attachment"
          label="Attach File(s)"
          label-for="Attach"
        >
        <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="newfile"
            />
          </b-form-group>

          <b-form-group
            v-if="selected.attachment"
            label="Attach File(s)"
            label-for="Attach"
          >
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-warning"
            @click="download(selected.attachment)">
            Download Attached File
          </b-button>
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="submit_now()"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                class="mr-2"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              Delete
            </b-button>
          </div>

        </b-form>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import Cleave from 'vue-cleave-component'
export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormFile,
    flatPickr,
    Cleave,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isExistInvoiceSidebarActive',
    event: 'update:is-exist-Invoice-sidebar-active',
  },
  props: {
    isExistInvoiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    selected: {
      required: false
    }

  },
  data() {
    return {
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          prefix: '$ ',
          numeralPositiveOnly: true,
          numeralDecimalScale: 2,
          numeralIntegerScale: 6,
          rawValueTrimPrefix: true,
          noImmediatePrefix: true,
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [30],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      required,
      alphaNum,
      email,
      countries,
      memberOptions:[],
      newfile:null,
      statusOptions: ['處理中','已送出','完成'],
      blankUserData: {
        date: '',
        amount: '',
        type: '',
        to: 'admin',
        note: '',
      },
    }
  },
  created() {
    //this.selected.member_id1 = { 'value' : 240 ,'label' : 'abv' }
    this.fetchOptions('id:'+this.selected.member_id)
    console.log(this.selected)
  },
  methods: {
    fetchOptions (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/search/member/?string='+search)
            .then(res => {
              this.memberOptions = res.data
            })
            }, 200);
      },
    download (file) {
      window.location.href = "https://www.cloudfit.pro/"+file;
    },
    submit_now() {
          var formData = new FormData();
        //  var imagefile = this.userData.file
        formData.append("invoice_date", this.selected.invoice_date);
        formData.append("invoice_code", this.selected.invoice_code);
        formData.append("member_id", this.selected.member_id.value);
        formData.append("amount", this.selected.amount);
        formData.append("paid", this.selected.paid);
        formData.append("sent_at", this.selected.sent_at);
        formData.append("settle_at", this.selected.settle_at);
        formData.append("staff_name", this.selected.staff_name);
        formData.append("status", this.selected.status);
        formData.append("remarks", this.selected.remarks);
        formData.append("file", this.newfile);

          this.$http.post(process.env.VUE_APP_API_BASE+'/invoice/edit/'+this.selected.id+'/', formData , {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.userData = this.blankUserData
                this.$refs.simpleRules.reset()
                this.newfile = null
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })


    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      display_name: '',
      username: '',
      password: '',
      role: 'admin',
      status: 'active',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },



}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
